<template>
  <div
    class="modal fade"
    id="addCatatan"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <form @submit.prevent="onSubmit">
            <div class="modal-header">
              <div class="modal-title text-center">Tambah Catatan</div>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <div class="row">
                  <div class="col-4">
                    <label>Catatan</label>
                  </div>
                  <div class="col-8">
                    <textarea class="form-control"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <!-- <button type="button" class="btn btn-close" data-dismiss="modal">Batal</button> -->
              <button type="submit" :disabled="isSubmit" class="btn btn-save">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">Edit Akun Perkiraan</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-jurnal-perkiraan"
                      >Akun Perkiraan
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Edit
                  </li>
                </ol>
              </nav>
            </div>
            <div class="button-edit" v-show="validated == 1 && access">
              <button class="btn-add" type="button" @click="editable">
                <img src="/img/icon-edit1.svg" alt="" style="width: 20px" />
                Edit
              </button>
            </div>
            <div v-show="validated != 1 && access">
              <button
                class="btn btn-save"
                :disabled="isSubmit"
                v-if="isEditable"
              >
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>

          <div class="card info-detail">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link active"
                  id="pills-home-tab"
                  data-toggle="pill"
                  href="#pills-home"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                  >Detail</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="pills-profile-tab"
                  data-toggle="pill"
                  href="#pills-profile"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                  >History</a
                >
              </li>
              <!-- <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="pills-rekonsilasi-tab"
                  data-toggle="pill"
                  href="#pills-rekonsilasi"
                  role="tab"
                  aria-controls="pills-rekonsilasi"
                  aria-selected="false"
                  >Rekonsiliasi Bank</a
                >
              </li> -->
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div class="row mt-5">
                  <div class="col-12">
                    <div class="title">Detail Akun Perkiraan</div>
                    <div class="sub-title">Edit informasi yang diperlukan</div>
                  </div>
                </div>
                <hr />

                <div class="row mt-3">
                  <div class="col-12" v-if="formData.status == 'tidak'">
                    <div class="alert alert-danger">
                      <h4 class="alert-heading" :style="{ fontSize: '17px' }">
                        Perhatian!
                      </h4>
                      <p class="mb-0">
                        Mengubah status menjadi
                        <strong>Nonaktif</strong> mengakibatkan akun dan sub
                        akun akan tidak muncul disemua halaman dan hal ini tidak
                        bisa dirubah kembali.
                      </p>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Nomor Akun</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="formData.id"
                        disabled
                      />
                    </div>
                    <div class="form-group">
                      <label for="inputAddress2">Nama Akun </label>
                      <input
                        :disabled="validated == 1"
                        type="text"
                        class="form-control"
                        @keyup="formChange('nama')"
                        v-model="formData.nama"
                        :class="{ 'is-invalid': formError && formError.nama }"
                        id="inputAddress2"
                        placeholder="Masukkan Nama Akun"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.nama"
                      >
                        {{ formError.nama }}
                      </div>
                    </div>
                    <!-- <div class="form-group">
                      <label for="inputAddress2">Departemen :</label>
                      <div class="form-group edit-vendor">
                        <Select2
                          v-model="formData.id_divisi"
                          :class="{
                            'is-invalid': formError && formError.id_divisi,
                          }"
                          :disabled="true"
                          :options="optionDepartement"
                          placeholder="Departemen"
                          @change="formChange('id_divisi')"
                          @select="formChange('id_divisi')"
                        />
                        <div
                          class="form-error"
                          v-if="formError && formError.id_divisi"
                        >
                          {{ formError.id_divisi }}
                        </div>
                      </div>
                    </div> -->
                  </div>

                  <div class="col-6">
                    <div class="form-group">
                      <label for="inputAddress2">Tipe Akun </label>
                      <Select2
                        v-model="formData.id_tipe"
                        disabled
                        :class="{
                          'is-invalid': formError && formError.id_tipe,
                        }"
                        :options="tipeAkun"
                        placeholder="List Tipe Akun"
                        @change="formChange('id_tipe')"
                        @select="formChange('id_tipe')"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.id_tipe"
                      >
                        {{ formError.id_tipe }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="inputAddress2">Parent Akun</label>
                      <Select2
                        v-model="formData.id_parent"
                        disabled
                        :class="{
                          'is-invalid': formError && formError.id_parent,
                        }"
                        :options="subAkun"
                        placeholder="List Sub Akun"
                        :settings="{ minimumResultsForSearch: 3 }"
                        @change="formChange('id_parent')"
                        @select="formChange('id_parent')"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.id_parent"
                      >
                        {{ formError.id_parent }}
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="inputAddress2">Status :</label>
                      <div class="form-group edit-vendor">
                        <Select2
                          v-model="formData.status"
                          :disabled="validated == 1"
                          :class="{
                            'is-invalid': formError && formError.status,
                          }"
                          :options="statusCompany"
                          placeholder="Status"
                          :settings="{ minimumResultsForSearch: -1 }"
                          @change="formChange('status')"
                          @select="formChange('status')"
                        />
                        <div
                          class="form-error"
                          v-if="formError && formError.status"
                        >
                          {{ formError.status }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="enableSaldo">
                  <div class="row mt-2">
                    <div class="col-12">
                      <div class="title">Saldo Awal</div>
                      <div class="sub-title">
                        Saldo awal pada akun perkiraan,
                        <span class="text-danger font-italic"
                          >Isi form di bawah ini jika ingin menyimpan data Saldo
                          Awal</span
                        >
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row mt-3">
                    <div class="col-6">
                      <div class="form-group">
                        <label class="" for="inlineFormInputGroupUsername"
                          >Nilai :</label
                        >
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="input-group-text">Rp</div>
                          </div>
                          <input
                            :disabled="validated == 1 || formSaldo.has_saldo"
                            type="text"
                            class="form-control text-right"
                            :model-modifiers="{ number: true }"
                            v-model.lazy="formSaldo.nilai"
                            placeholder="Nilai"
                            v-money="configMoney"
                            :class="{
                              'is-invalid': formErrorSaldo && formErrorSaldo.nilai,
                            }"
                            @keyup="formChange('nilai', 'saldo')"
                          />
                        </div>
                        <div
                          class="form-error"
                          v-if="formErrorSaldo && formErrorSaldo.nilai"
                        >
                          {{ formErrorSaldo.nilai }}
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="inputAddress2">Akun Beban</label>
                        <Select2
                          v-model="formSaldo.beban"
                          :disabled="validated == 1 || formSaldo.has_saldo"
                          :options="optionAkunBeban"
                          placeholder="List Akun"
                          :settings="{ templateResult: formatState }"
                          @change="formChange('beban', 'saldo')"
                          @select="formChange('beban', 'saldo')"
                          :class="{
                              'is-invalid': formErrorSaldo && formErrorSaldo.beban,
                            }"
                        />
                        <div
                          class="form-error"
                          v-if="formErrorSaldo && formErrorSaldo.beban"
                        >
                          {{ formErrorSaldo.beban }}
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="inputAddress2">Per Tanggal </label>
                        <Datepicker
                          :disabled="validated == 1 || formSaldo.has_saldo"
                          :autoApply="true"
                          v-model="formSaldo.tanggal"
                          :format="format"
                          :enableTimePicker="false"
                          locale="id-ID"
                          selectText="Pilih"
                          cancelText="Batal"
                          @update:modelValue="changeDate"
                          placeholder="Pilih Tanggal"
                          :class="{
                            'is-invalid': formErrorSaldo && formErrorSaldo.tanggal,
                          }"
                        ></Datepicker>
                        <div
                          class="form-error"
                          v-if="formErrorSaldo && formErrorSaldo.tanggal"
                        >
                          {{ formErrorSaldo.tanggal }}
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="inputAddress2">Departemen :</label>
                        <div class="form-group edit-vendor">
                          <Select2
                            v-model="formSaldo.id_divisi"
                            :class="{
                              'is-invalid': formErrorSaldo && formErrorSaldo.id_divisi,
                            }"
                            :options="optionDepartement"
                            placeholder="Departemen"
                            @change="formChange('id_divisi', 'saldo')"
                            @select="formChange('id_divisi', 'saldo')"
                          />
                          <div
                            class="form-error"
                            v-if="formErrorSaldo && formErrorSaldo.id_divisi"
                          >
                            {{ formErrorSaldo.id_divisi }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Sumber Saldo</label>
                        <input
                          :disabled="validated == 1 || formSaldo.has_saldo"
                          type="text"
                          class="form-control"
                          v-model="formSaldo.sumber"
                          @keyup="formChange('sumber', 'saldo')"
                          :class="{
                            'is-invalid': formErrorSaldo && formErrorSaldo.sumber,
                          }"
                          placeholder="Sumber Saldo"
                        />
                        <div
                          class="form-error"
                          v-if="formErrorSaldo && formErrorSaldo.sumber"
                        >
                          {{ formErrorSaldo.sumber }}
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="catatan">Catatan:</label>
                        <textarea
                          :disabled="validated == 1 || formSaldo.has_saldo"
                          type="text"
                          class="form-control"
                          id="catatan"
                          v-model="formSaldo.keterangan"
                          @keyup="formChange('keterangan', 'saldo')"
                          placeholder="Masukkan Catatan"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div class="row mt-5">
                  <div class="col-12">
                    <div class="title">History Akun {{ namaAkun }}</div>
                    <div class="sub-title">
                      Edit informasi history akun {{ namaAkun }} yang diperlukan
                    </div>
                  </div>
                </div>
                <hr />

                <div class="table-responsive">
                  <table
                    class="table table-bordered table-striped"
                    id="tableHistorySaldo"
                  >
                    <thead>
                      <tr>
                        <th style="width: 15%">Tanggal</th>
                        <th style="width: 15%">Sumber</th>
                        <th style="width: 20%">Tipe Transaksi</th>
                        <th style="width: 20%">Keterangan</th>
                        <th style="width: 20%">Mutasi</th>
                        <th style="width: 10%">Tipe</th>
                        <th style="width: 10%">Saldo</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(value, index) in history" :key="index">
                        <td>{{ convertDate(value.tanggal) }}</td>
                        <td>{{ value.sumber }}</td>
                        <td>{{ value.tipe_transaksi }}</td>
                        <td>{{ value.keterangan }}</td>
                        <td class="text-right">
                          {{ formatMoney(value.mutasi) }}
                        </td>
                        <td class="text-capitalize">{{ value.tipe }}</td>
                        <td class="text-right">
                          {{ formatMoney(value.saldo) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- <div
                class="tab-pane fade"
                id="pills-rekonsilasi"
                role="tabpanel"
                aria-labelledby="pills-rekonsilasi-tab"
              >
                <div class="row mt-5">
                  <div class="col-12">
                    <div class="title">Rekonsiliasi Bank Akun Perkiraan</div>
                    <div class="sub-title">
                      Edit informasi akun perkiraan yang diperlukan
                    </div>
                  </div>
                </div>
                <hr />

                <div class="">
                  <table
                    class="table table-bordered table-striped"
                    id="tableHistorySaldo"
                  >
                    <thead>
                      <tr>
                        <th style="width: 15%">Tanggal</th>
                        <th style="width: 15%">Sumber</th>
                        <th style="width: 20%">Tipe Transaksi</th>
                        <th style="width: 20%">Keterangan</th>
                        <th style="width: 20%">Mutasi</th>
                        <th style="width: 10%">Tipe</th>
                        <th style="width: 10%">Saldo</th>
                        <th style="width: 10%">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>11 Jan 2022</td>
                        <td>012345678</td>
                        <td>Jurnal Umum</td>
                        <td>Saldo Awal</td>
                        <td>12,345,678,900</td>
                        <td>Dr</td>
                        <td>12,345,678,900</td>
                        <td>
                          <div class="wrapper-aksi">
                            <div class="detail-aksi" id="1">
                              <div
                                @click="addCatatan()"
                                class="d-flex mb-2 pointer align-items-center"
                              >
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13.5 4.5L4.5 13.5"
                                    stroke="#FF3D3D"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M4.5 4.5L13.5 13.5"
                                    stroke="#FF3D3D"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>

                                <div>Mutasi Bank</div>
                              </div>
                              <div
                                class="d-flex mb-2 pointer align-items-center"
                              >
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M15 4.5L6.75 12.75L3 9"
                                    stroke="#3CB778"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>

                                <div>Mutasi Bank</div>
                              </div>
                              <div class="d-flex pointer align-items-center">
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clip-path="url(#clip0_5163_102592)">
                                    <path
                                      d="M13.5 1.5H4.5C3.675 1.5 3 2.175 3 3V15C3 15.825 3.675 16.5 4.5 16.5H13.5C14.325 16.5 15 15.825 15 15V3C15 2.175 14.325 1.5 13.5 1.5ZM6.75 3H8.25V6.75L7.5 6.1875L6.75 6.75V3ZM13.5 15H4.5V3H5.25V9.75L7.5 8.0625L9.75 9.75V3H13.5V15Z"
                                      fill="#898989"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5163_102592">
                                      <rect
                                        width="18"
                                        height="18"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>

                                <div>Jurnal</div>
                              </div>
                            </div>
                          </div>
                          <div
                            ref="button"
                            class="toggle-button"
                            @click="aksi(1)"
                          >
                            <svg
                              class="pointer"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                                stroke="#898989"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                                stroke="#898989"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                                stroke="#898989"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>11 Jan 2022</td>
                        <td>012345678</td>
                        <td>Jurnal Umum</td>
                        <td>Saldo Awal</td>
                        <td>12,345,678,900</td>
                        <td>Dr</td>
                        <td>12,345,678,900</td>
                        <td>
                          <div class="wrapper-aksi">
                            <div class="detail-aksi" id="2">
                              <div class="d-flex mb-2 align-items-center">
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13.5 4.5L4.5 13.5"
                                    stroke="#FF3D3D"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M4.5 4.5L13.5 13.5"
                                    stroke="#FF3D3D"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>

                                <div>Mutasi Bank</div>
                              </div>
                              <div class="d-flex mb-2 align-items-center">
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M15 4.5L6.75 12.75L3 9"
                                    stroke="#3CB778"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>

                                <div>Mutasi Bank</div>
                              </div>
                              <div class="d-flex align-items-center">
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clip-path="url(#clip0_5163_102592)">
                                    <path
                                      d="M13.5 1.5H4.5C3.675 1.5 3 2.175 3 3V15C3 15.825 3.675 16.5 4.5 16.5H13.5C14.325 16.5 15 15.825 15 15V3C15 2.175 14.325 1.5 13.5 1.5ZM6.75 3H8.25V6.75L7.5 6.1875L6.75 6.75V3ZM13.5 15H4.5V3H5.25V9.75L7.5 8.0625L9.75 9.75V3H13.5V15Z"
                                      fill="#898989"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5163_102592">
                                      <rect
                                        width="18"
                                        height="18"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>

                                <div>Jurnal</div>
                              </div>
                            </div>
                          </div>

                          <svg
                            @click="aksi(2)"
                            class="pointer"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                              stroke="#898989"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                              stroke="#898989"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                              stroke="#898989"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> -->
            </div>
            <!-- <div class="row ">
                            <ul class="nav nav-pills">
                                <li class="nav-item">
                                    <router-link to="/data-jurnal-perkiraan/detail" class="nav-link active">Detail
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/data-jurnal-perkiraan/history" class="nav-link ">History
                                    </router-link>
                                </li>
                            </ul>
                        </div> -->
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";
import Datepicker from "vue3-date-time-picker";
import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
import moment from "moment";
import "moment/locale/id";
import {
  checkRules,
  cksClient,
  showAlert,
  checkModuleAccess,
} from "../../../helper";
import { VMoney } from "v-money";
import {
  get_AkunDetail,
  get_AkunList,
  get_AkunTipeList,
  post_AkunSave,
} from "../../../actions/akun_perkiraan";
import { get_ListDivisi } from "../../../actions/master";

export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
    // TableLite
  },

  directives: {
    money: VMoney,
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  data() {
    return {
      isOpen: false,
      showInside: false,
      access: true,
      validated: 1,
      isEditable: true,
      checkAccess: checkModuleAccess,
      myValue: "aaaa",
      namaAkun: "",
      statusCompany: [
        { id: "aktif", text: "Aktif" },
        { id: "tidak", text: "Nonaktif" },
      ], // or [{id: key, text: value}, {id: key, text: value}]
      tipeAkun: [],
      subAkun: [],
      optionDepartement: [],
      optionAkunBeban: [],
      placeholder: [
        {
          text: "kecamatan",
        },
      ],
      id_akun: this.$route.params.id,
      formData: {
        id: this.$route.params.id,
        nama: "",
        id_company: cksClient().get("_account").id_company,
        id_tipe: "",
        id_parent: "",
        id_divisi: "",
        status: "aktif",
      },
      formError: [],
      onLoadData: false,
      rules: {
        nama: {
          required: true,
        },
        id_tipe: {
          required: true,
        },
        status: {
          required: true,
        },
        id_divisi: {
          required: false,
        },
      },
      formSaldo: {
        nilai: "",
        keterangan: "",
        tanggal: "",
        sumber: "",
        beban: "",
        id_divisi: "",
        has_saldo: false,
      },
      saldoRules: {
        nilai: {
          required: false,
        },
        tanggal: {
          required: false,
        },
        sumber: {
          required: false,
        },
        beban: {
          required: false,
        }
      },
      formErrorSaldo: [],
      configMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      history: [],
      id_company: cksClient().get("_account").id_company,
      isSubmit: false,
      dataTable: "",
      enableSaldo: true,
    };
  },

  created() {
    this.onLoadData = true;
    this.getAkun();
    this.getListAkun();
    // this.getSubAkun()
    this.getDivisi();
    this.getTipe();
    if (!this.checkAccess("ledger", "cru_acoount_estimates")) {
      this.access = false;
    }
  },

  mounted() {
    setTimeout(() => {
      $("#pills-home-tab").tab("show");
    }, 600);
    if (!this.id_akun && !this.onLoadData) {
      showAlert(this.$swal, {
        title: "PERHATIAN!",
        msg: "Nomor akun tidak ditemukan",
        showCancelButton: true,
        showConfirmButton: false,
        onCancel: () => {
          this.$router.push({
            name: "EstimatedAccount",
          });
        },
      });
    }
  },

  methods: {
    aksi(index) {
      $(".detail-aksi").not(`${index}`).removeClass("active");
      $(`.detail-aksi#${index}`).addClass("active");
    },
    addCatatan() {
      $("#addCatatan").modal("show");
    },

    editable() {
      this.validated = 2;
    },
    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    convertDate(string) {
      return moment(string).format("DD MMM YYYY");
    },
    getDivisi() {
      get_ListDivisi({ id_company: this.id_company }, (res) => {
        this.optionDepartement = res.list;
      });
    },
    getAkun() {
      get_AkunDetail(
        this.id_akun,
        (res) => {
          const { data, history,saldo_awal } = res;
          this.namaAkun = data.nama;
          this.onLoadData = false;
          this.formData = { ...this.formData, ...data };
          this.enableSaldo = true;
          if (this.formData.id_parent) {
            this.enableSaldo = true;
          }
          delete this.formData.updated_at;
          delete this.formData.created_at;
          this.history = history;
          if (saldo_awal.has_saldo) {
            this.formSaldo = {...this.formSaldo, ...saldo_awal}
          }
          this.formChange("id_tipe");
          if (this.dataTable) {
            this.dataTable.destory();
          }
          setTimeout(() => {
            this.dataTable = $("#tableHistorySaldo").DataTable({
              info: false,
              order: [],
            });
          }, 1000);
        },
        () => {
          this.onLoadData = false;
          showAlert(this.$swal, {
            title: "ERROR!",
            msg: "Data tidak ditemukan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
            onCancel: () => {
              this.$router.push({
                name: "EstimatedAccount",
              });
            },
          });
        }
      );
    },
    getTipe() {
      get_AkunTipeList((res) => {
        this.tipeAkun = res.list;
      });
    },
    getListAkun(){
      get_AkunList(
        {
          id_company: this.id_company,
          tipe: "",
        },
        (res) => {
          var data = [];
          const { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }

          this.optionAkunBeban = data;
        }
      );
    },
    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-3" : state.sub_level == 1 ? "pl-2" : "";
      var $state = $(
        '<div class="' +
          padding +
          '">' +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.no +
          "<div>" +
          state.type +
          "</div></div></div>"
      );
      return $state;
    },
    getSubAkun() {
      get_AkunList(
        {
          id_company: this.id_company,
          sub_akun: true,
          tipe: this.formData.id_tipe,
        },
        (res) => {
          const { list } = res;
          this.subAkun = [];
          for (let i = 0; i < list.length; i++) {
            const element = list[i];
            this.subAkun.push({
              id: element.id,
              text: element.nama,
            });
          }
        }
      );
    },
    async formChange(key, tipe = '') {
      if (tipe == 'saldo') {
        if(this.formSaldo.nilai != "" && this.formSaldo.nilai != 0 && this.formSaldo.nilai != "0") {
          this.saldoRules.beban.required = true;
          this.saldoRules.tanggal.required = true;
          this.saldoRules.sumber.required = true;
        } else {
          this.saldoRules.beban.required = false;
          this.saldoRules.tanggal.required = false;
          this.saldoRules.sumber.required = false;
        }
        if (this.saldoRules[key]) {
          this.saldoRules[key].changed = true;
        }
        let check = await checkRules(this.saldoRules, this.formSaldo);
        this.formErrorSaldo = check.error;
      } else {
        if (this.rules[key]) {
          this.rules[key].changed = true;
        }
        if (key == "id_tipe" && this.formData[key]) {
          this.getSubAkun();
        } else if (key === "id_tipe") {
          this.subAkun = [];
        }
        let check = await checkRules(this.rules, this.formData);
        this.formError = check.error;
      }
    },

    changeDate(data) {
      this.formSaldo.tanggal = moment(data).format("YYYY-MM-DD");
      this.formChange('tanggal', 'saldo')
    },
    postData() {
      this.isSubmit = true;
      var data = {...this.formData}
      data.saldo = this.formSaldo
      post_AkunSave(
        data,
        (res) => {
          this.isSubmit = false;
          var msg =
            res.response_code === 201
              ? "Data berhasil disimpan"
              : "Data berhasil diperbarui";
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              this.$router.push({ name: "EstimatedAccount" });
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }

      for (const key in this.saldoRules) {
        if (Object.hasOwnProperty.call(this.saldoRules, key)) {
          this.saldoRules[key].changed = true;
        }
      }

      let check = await checkRules(this.rules, this.formData);
      this.formError = check.error;

      let checkSaldo = await checkRules(this.saldoRules, this.formSaldo);
      this.formErrorSaldo = checkSaldo.error;
      if (check.success && checkSaldo.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.id
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.btn-save {
  background-color: #40ddd4;
  color: #fff;
  /* width: 90px; */
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0px;
}
.wrapper-aksi {
  position: relative;
}
.detail-aksi {
  line-height: 20px;
  width: 111px;
  top: -61px;
  padding: 10px;
  display: none;
  border-radius: 10px;
  background: #fff;
  position: absolute;
  font-size: 10px;
}
.detail-aksi svg {
  margin-right: 10px;
}

.detail-aksi.active {
  display: block;
}

.pointer {
  cursor: pointer;
}
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.card .nav .nav-item {
  margin-right: 30px;
}

.card {
  padding: 20px 50px;
  widows: 100%;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

.cr {
  color: #ff3d3d;
}
</style>
